Export.labels[ECN.CLIENT] = {
    symbol: 'Kod kl.',
    name: 'Nazwa kl.'
};

Export.fields[ECN.CLIENT] = {
    order: ['name', 'symbol'],
    pricelist: ['name', 'symbol'],
    basic: ['symbol']

};

Export.pdf.widths[ECN.CLIENT] = {
    symbol: 50,
    name: '*'
};

Export.pdf.createGenerator(ECN.CLIENT, 'generic', {


});