Export.labels[ECN.CLIENTGROUP] = {
    symbol: 'Kod kl.',
    name: 'Nazwa kl.'
};

Export.fields[ECN.CLIENTGROUP] = {
    order: ['name', 'symbol'],
    pricelist: ['name', 'symbol'],
    basic: ['symbol']

};

Export.pdf.widths[ECN.CLIENTGROUP] = {
    symbol: 50,
    name: '*'
};

Export.pdf.createGenerator(ECN.CLIENTGROUP, 'generic', {


});