Export.labels[ECN.POSITION] = {
    nr: 'Nr',
    width: 'Szer.[mm]',
    widthcm: 'Szer.[cm]',
    length: 'Dł.[mm]',
    lengthcm: 'Dł.[cm]',
    quantity: 'Ilość',
    area: 'Pow.[m2]',
    model: 'Model',
    size: 'Grubość',
    trims: 'Opcje',
    color: 'Kolor',
    value: 'Kwota netto',
    valueBrutto: 'Kwota brutto'

};

Export.pdf.widths[ECN.POSITION] = {
    nr: 15,
    width: 40,
    widthcm: 40,
    length: 40,
    lengthcm: 40,
    quantity: 40,
    area: 40, 
    value: 40, 
    valueBrutto: 40, 
    color: '*',
    model: '*',
    size: '*',
    trims: '*'
};

Export.fields[ECN.POSITION] = {
    basic: ['nr', 'width', 'length', 'quantity', 'area', 'value', 'valueBrutto', 'size', 'model', 'color', 'trims'],
    basicReport: ['number', 'created'],
    order: [],
    summary: {
        basic: ['quantity', 'area', 'value', 'valueBrutto']
    }
};

Export.xls.createGenerator(ECN.POSITION, 'generic', {
    _getContent:function(){       
        return this.exporter.generate(this.data, ['Pozycja']);
    }
});

Export.pdf.addConverters(ECN.POSITION, {
    color: Export.pdf.converters.diffDic,
    model: Export.pdf.converters.diffDic,
    size: Export.pdf.converters.diffDic,
    trims: Export.pdf.converters.diffTrims
});

Export.pdf.createGenerator(ECN.POSITION, 'generic', {
    genTable:function(data, options){
        if(!B.obj.is(data)){
            data = this.data;
        }
        var o = $.extend(true, {
                    fieldsType: 'basic',
                    tableOptions: {
                        options: {
                            margin: [0, 5, 0, 0],
                            fontSize: 8,
                            layout: 'noBorders',
                        } 
                    } 
                },
                options || {}
            ),            
            pdf = this.exporter,
            ecn = ECN.POSITION,
            fields = Export.getFields(ecn, o.fieldsType),
            rows=[
                pdf._rowStyle(Export._labels(fields, ecn), 'tableHeader'),
            ], 
            co = $.extend(true, {ecn: ecn}, o.convertOptions || {} );
        for(var i in data){            
            rows.push(pdf.convertFields(data[i], fields, co));
        }
        if(o.summary){
            var rowSummary=[{text: 'Podsumowanie', colSpan: 3},'','']; 
            rowSummary = rowSummary.concat(pdf.convertFields(o.summary, Export.getFields(ecn, o.fieldsType, 'summary'), co));
            rowSummary.push({text:'', colspan: fields.length - rowSummary.length });
            pdf._fillEmptyCells(rowSummary, fields.length - rowSummary.length );
            rows.push(pdf._rowStyle(rowSummary, 'tableHeader'));
        }
        return pdf._table(rows,
            $.extend(true, {
                options: {
                    table: {
                        widths: pdf._cellsWidth(fields, {tableName : o.TableName || ecn })
                    }
                }
            }, o.tableOptions)
        );    
    },
    _getContent:function(){       
        return null;
    }
});