Export.labels[ECN.PRODUCT] = {
    generated: 'Utworzona'
};

Export.fields[ECN.PRODUCT] = {
        basic: ['number', 'created'],
        basicReport: ['number', 'created']
    };


Export.xls.createGenerator( ECN.PRODUCT, 'generic', {
    order: function() {},
    generate: function(xls) {
        this.xls = xls;
        xls.generators.title('Produkt');
    }
});

Export.pdf.addConverters(ECN.PRODUCT, {

});

