Export.labels[ECN.CALCULATION] = {
    created: 'Utworzona',
    token: 'Token',
};

Export.filenameOptions[ECN.CALCULATION] = {
    prefix: 'kalkulacja_',
    suffix: '',
    generator:function(){
        return this.data.token;
    } 
};

Export.fields[ECN.CALCULATION] = {
    basic: ['created', 'token'],
};

Export.pdf.addConverters(ECN.CALCULATION, {

});

Export.pdf.createGenerator(ECN.CALCULATION, 'generic', {
    genBasic: function(type) {
        return this._gen( 'standardTable', [
            this.data,
            {
                fieldsType: 'basic'
            }
        ]);
    },
    genHeader: function(){
        return this._gen('header', ['Kalkulacja parapetów']);
    },
    genParameters:function(){
        return this._gen('genParameters', [this.data.content], ECN.ORDER, 'generic' );
    },
    genPositions:function(){
        return this._gen('genPositions', [this.data.content], ECN.ORDER, 'generic' );
    },
    genContent:function(){
        var content=[
            this.genHeader(),
            this.genBasic(),
            this.genParameters(),
            this.genPositions()
        ];
        return content;
    }
});

