Export.labels[ECN.PRICELIST] = {
    start: 'Od',
    end: 'Do',
    title: 'Nazwa cennika'
};

Export.filenameOptions[ECN.PRICELIST] = {
    prefix: 'cennik_',
    suffix: '',
    generator:function(){
        return this.data.title;
    } 
};

Export.fields[ECN.PRICELIST] = {
    basic: ['title'],
    period: ['start', 'end']

};

Export.pdf.widths[ECN.PRICELIST] = {
    title: '*',
    start: 110,
    end: 110,
    description: '*'
};

Export.pdf.createGenerator(ECN.PRICELIST, 'generic', {
    genHeader: function(){
        return this._gen('header', ['Cennik '+ this.data.title]);
    },
    genPeriod: function(type) {
        return this._gen( 'standardTable', [
            this.data,
            {
                fieldsType: 'period'
            }
        ]);
    },
    genClients:function(){
        return this._gen( 'standardTable', [
            this.data.client,
            {
                fieldsType: 'pricelist',
                ecn: ECN.CLIENT
            }
        ]);
    },
    genPrices:function(){
        var
            pdf = this.exporter, 
            d = this.data.prices,
            rows = [],
            header = [''],
            fields = ['color'],
            row,
            i, j;
            for(i in d.size){
                header.push(B.getFromDictionary(null, ECN.SIZE, d.size[i]));
                fields.push('size');
            }
            rows.push(pdf._rowStyle(header, 'tableHeader'));
            for(i in d.pricesTable){
                row = [pdf._styleText(B.getFromDictionary(null, ECN.COLOR, d.pricesTable[i].color), 'bold')];
                for(j in d.pricesTable[i].values){
                    row.push(d.pricesTable[i].values[j]
                        ? pdf.converters.price(d.pricesTable[i].values[j])
                        : ''
                    );
                }
                rows.push(row);
            }

        return pdf._table(rows, {
            options: {
                stripped: true,
                margin: [0, 5, 0, 0],
                fontSize: 8,
                table: {
                    widths: pdf._cellsWidth(fields, { def: '*'})
                }
            }
        });
    },
    genContent:function(){
        var content=[
            this.genHeader(),
            this.genPeriod(),
            this.genPrices()
        ];
        return content;
    }
});