Export.labels[ECN.PRODUCTION] = {
    generated: 'Utworzona',
    position: {
        length: 'długość',
        width:'szerokość',
        size: 'grubość',
        model: 'frez',
        trims: 'opcje',
        color: 'kolor',   
    },
    order: {
        number: 'nr zamówienia',
        clientNumber: 'nr zamówienia klienta',
        client: 'klient',
        approved: 'data przyjęcia',
        quantity: 'ilość sztuk',
    },
    pr_barcode: 'kod kreskowy',
    pr_clientBarcode: 'kod kreskowy klienta',
    nr_elementu: 'nr elementu',
    nazwa_produktu: 'nazwa produktu',
    nazwa_z_biblioteki: 'nazwa z bilblioteki',
    plyta	: 'płyta',
    kat_slojow: 'kąt słojów',
    kat_kroku: 'kąt kroku',
    FMCINCLUDE: 'FMCINCLUDE',
    FMCKONTUR: 'FMCKONTUR',
    one: 'one',
    order_id: 'id zamówienia'
        
};

var _extraProduct={
    'nazwa_produktu': 'MDF',
    'nazwa_z_biblioteki': 'baza',
    'plyta'	: '',
    'kat_slojow': '0',
    'kat_kroku': '1',
    'FMCINCLUDE': 'baza.fmc',
    'FMCKONTUR': 'baza_k.fmc',
    'one': '1'
};

Export.fields[ECN.PRODUCTION] = {
    basic: ['number', 'created'],
    basicReport: ['number', 'created'],
    order: {order_id: 'id', order_client : 'client.code', order_approved:'approved', order_number:'number', order_clientNumber:'clientNumber', order_quantity:'quantity'},
    position:{ pos_length:'length', pos_width:'width', pos_size: 'size.symbol', pos_model:'model.symbol', pos_trims:'trims', pos_color : 'color.symbol', plyta: 'size.symbol'},
    product: {pr_id : 'id', pr_label:'label', pr_clientLabel:'clientLabel', pr_barcode:'barcode', pr_clientBarcode:'clientBarcode', pr_number:'number', nr_element: 'number'},
    products: [ 'position.length', 'position.width', 'position.size', 'position.model', 'position.trims', 'order.client', 'order.approved', 'order.number', 'order.clientNumber', 'position.color', 'order.quantity', 'nr_element', 'nazwa_produktu', 'pr_barcode', 'pr_clientBarcode', 
        'position.size',
        'kat_slojow',
        'kat_kroku',
        'FMCINCLUDE',
        'FMCKONTUR',
        'one',
        'order.id'
    ]       

};

Export.xls.addConverters(ECN.PRODUCTION, {
    pos_length: Export.xls.converters.toInt, 
    pos_width: Export.xls.converters.toInt, 
    order_quantity: Export.xls.converters.toInt, 
    pr_barcode: function(value, diff) {
        return '*'+ Export.xls.converters.validateBarcode(value) +'*';
    },
    nr_element:function(value, diff) {
        return value.slice(value.indexOf('-')+1).split('/')[0];
    },
    order_number:function(value, diff) {
        return value.slice(0, value.indexOf('/'));
    },
    pr_clientBarcode:function(value, diff) {
        return '*'+ Export.xls.converters.validateBarcode(value) +'*';
    },
    products:function(xls){
        var products=[], 
            _convertFields=function(data, ecn){
                for(var l in data){
                    data[l]=xls.convertField(data[l], l );
                }
                return data;
            };
        for(var i in xls.data.orders){
            var nrElement=1,
                order=xls.data.orders[i],
                orderData=_convertFields(Bajt.entity.export(order, ECN.ORDER, Export.fields[ECN.PRODUCTION].order));
            for(var j in order.positions){
                var position=order.positions[j],
                    positionData=_convertFields(Bajt.entity.export(position, ECN.POSITION, Export.fields[ECN.PRODUCTION].position));
                for(var k in position.products){
                    var product=position.products[k],
                        productData=_convertFields(Bajt.entity.export(product, ECN.PRODUCT, Export.fields[ECN.PRODUCTION].product));
                    products.push($.extend(productData, positionData, orderData, _extraProduct, {kat_kroku: position.pos_length >  2790 || position.pos_width >  2790 ? 1 : 90 }));
                }
            }
        }
        console.log(products);
        return products;
    }
});

Export.xls.createGenerator( ECN.PRODUCTION, 'generic', {
    genHeader:function(){
        this._gen('header', ['Produkcja nr ' + this._getValue('number') + ' z dnia '+ this._getValue('generated')]);
    },
    genProducts:function(){
        var rowIndex = 2, 
            fields = Export.getFields(this.ecn, 'products'),
            i, 
            xls = this.exporter;
        xls.blockData(this._labels(fields), {rowIndex: rowIndex++, cellIndex: 0 });
        for(i in this.data.products){
            var rowData = this.data.products[i];
            xls.blockData(Export._dataToArray(rowData, fields, this.ecn, {kat_slojow: 0 }, { key: 'symbol'}), {rowIndex: rowIndex++, cellIndex: 0 });
        }

    },
    genContent: function() {
        // var rowIndex=1,
            // products=xls.converters.Productions.products(xls);
        this.genHeader();
        this.genProducts();
        //     xls.generators.title('Produkcja nr ' + Bajt.getFieldValue(xls.data, 'number', 'Productions') + ' z dnia '+ Bajt.getFieldValue(xls.data, 'generated', 'Productions'), {rowIndex: rowIndex++, cellIndex: 0});
        // xls.blockData(Export._labelFields( Export.fields.Productions.products , 'Productions'), {rowIndex: rowIndex++, cellIndex: 0 });
        // for(var i in products){
        //     xls.blockData(products[i], {rowIndex: rowIndex++, cellIndex: 0, fields: Export.fields.Productions.products });
        // }
    }
});


