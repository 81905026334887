Export.labels[ECN.ORDER] = {
    created: 'Utworzone',
    number: 'Numer',
    client: 'Klient',
    orderComment: 'Uwagi do zamówienia',
    color: 'Kolor',
    size: 'Grubość',
    model: 'Frez',
    trims: 'Opcje',
    double: 'Dwustronnie'
};

Export.filenameOptions[ECN.ORDER] = {
    prefix: 'zam_',
    suffix: '',
    generator:function(){
        return this.data.number;
    } 
};

Export.fields[ECN.ORDER] = {
    basic: ['number', 'created'],
    parameters: ['size', 'model', 'color', 'trims'],
    comments: ['orderComment']

};

Export.xls.createGenerator(ECN.ORDER, 'generic', {
    basic: function() {
        var xls = this.exporter;
        var fields = Export.fields.Orders.basicCSV,
            labels = Export._labels(fields, ECN.ORDER);
        var cellIndex = 0,
            rowIndex = xls.current.row.idx;
        for (var i = 0, ien = fields.length; i < ien; i++) {
            xls._cell(labels[i], {
                rowIndex: rowIndex,
                cellIndex: i
            });
            xls._cell(Bajt.getFieldValue(xls.data, fields[i], ECN.ORDER), {
                rowIndex: rowIndex + 1,
                cellIndex: i
            });
        }
        xls.current.row.idx = rowIndex + 2;
    },
    parameters: function() {
        var xls = this.exporter;
        var fields = Export.fields.Orders.param,
            labels = Export._labels(fields, ECN.ORDER);
        var rowIndex = xls.current.row.idx;
        for (var i = 0, ien = fields.length; i < ien; i++) {
            xls._cell(labels[i], {
                rowIndex: rowIndex,
                cellIndex: i
            });
            xls._cell(xls.convertField(Bajt.getFieldValue(xls.data, fields[i], ECN.ORDER), fields[i]), {
                rowIndex: rowIndex + 1,
                cellIndex: i
            });
        }
        xls.current.row.idx = rowIndex + 2;
    },
    positions: function() {
        var i,
            ien,
            xls = this.exporter,
            fields = Export.fields[ECN.POSITION].all,
            labels = Export._labels(fields, ECN.POSITION),
            rowIndex = xls.current.row.idx;
        for (i = 0, ien = fields.length; i < ien; i++) {
            xls._cell(labels[i], {
                rowIndex: rowIndex,
                cellIndex: i
            });
        }
        rowIndex++;
        for (i = 0, ien = xls.data.positions.length; i < ien; i++) {
            var pos = xls.data.positions[i],
                cellIndex = 0;
            for (var j = 0, jen = fields.length; j < jen; j++) {
                xls._cell(xls.convertField(pos[fields[j]], fields[j]), {
                    rowIndex: rowIndex,
                    cellIndex: cellIndex
                });
                cellIndex++;
            }
            rowIndex++;
        }
        xls.current.row.idx = rowIndex;
    },
    genContent: function() {
        this.externalGenerator('title', ['nagłówek']);
        this.basic();
        this.parameters();
        this.positions();
    }
});

Export.pdf.addConverters(ECN.ORDER, {
    color: Export.pdf.converters.dic,
    model: Export.pdf.converters.dic,
    size: Export.pdf.converters.dic,
    trims: Export.pdf.converters.trims
});

Export.pdf.createGenerator(ECN.ORDER, 'generic', {
    genClient:function(){
        return this._gen( 'standardTable', [
            this.data.client,
            {
                fieldsType: 'order',
                ecn: ECN.CLIENT
            }
        ]);
    },
    genBasic: function(type) {
        return this._gen( 'standardTable', [
            this.data,
            {
                fieldsType: 'basic'
            }
        ]);
    },
    genGen:function(){
        return this.exporter._table([
            [this.genClient(), this.genBasic()]
        ],
        {
            options: {
                margin: [0, 5, 0, 0],
                fontSize: 8,
                layout: 'noBorders',
                table:{
                    widths: ['*', '*']
                }
            } 
        }
        );
    },
    genParameters: function(data, options){
        return this._gen( 'standardTable', [
            data || this.data,
            $.extend({
                fieldsType: 'parameters'
            }, options || {})
        ]);
    },
    genComments:function(data, options){
        return this._gen( 'standardTable', [
            data || this.data,
            $.extend({
                fieldsType: 'parameters'
            }, options || {})
        ]);
    },
    genHeader: function(){
        return this._gen('header', ['Zamówienie nr '+ this.data.number]);
    },
    genPositions:function(data, options){
        data = data || this.data;
        var posTable= this._gen('genTable', [data.positions, 
            $.extend({convertOptions: { sameDisp: '-' }, summary: { quantity: data.quantity, area: data.area, value: data.value } }, options || {})
        ], ECN.POSITION );
        return posTable;
    },
    genContent:function(){
        var content=[
            this.genHeader(),
            this.genGen()
        ];
        if(this.data.orderComment){
            content.push(this.genComments());
        }
        content.push(this.genParameters());
        content.push(this.genPositions());
        return content;
    }
});

Export.pdf.createGenerator(ECN.ORDER, 'calculation', {
    genBasic: function(type) {
        return this._gen( 'standardTable', [
            this.data,
            {
                fieldsType: type || 'basic'
            }
        ]);
    },
    genParameters: Export.pdf.generators[ECN.ORDER].generic.genParameters,
    genPositions: Export.pdf.generators[ECN.ORDER].generic.genPositions,
    genContent:function(){
        var content=[
        ];
        content.push(this.genParameters());
        content.push(this.genPositions());
        return content;
    }
});

